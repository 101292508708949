<template>
    <validation-observer ref="observer" tag="div" v-slot="{invalid}">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1>{{ isEditing ? 'Actualizar Empresa' : 'Nueva Empresa' }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container has-text-left">
          <section class="box">
            <div class="block">
              <validation-provider rules="required" name="company">
                <b-field slot-scope="{ errors, valid }"
                         horizontal
                         label="Empresa" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                         :message="errors">
                  <b-input placeholder="Nombre de la empresa" v-model="companyForm.name"></b-input>
                </b-field>
              </validation-provider>
              <validation-provider rules="required" name="description">
                <b-field horizontal label="Descripción">
                  <b-input maxlength="100" type="textarea" placeholder="Información de interés"
                           v-model="companyForm.description"></b-input>
                </b-field>
              </validation-provider>
              <b-field v-if="false" horizontal label="Imagen de cabecera">
                <b-upload v-model="dropFiles"
                          multiple: false
                          drag-drop>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                          icon="upload"
                          size="is-large">
                        </b-icon>
                      </p>
                      <p>Arrastra aquí la imagen de cabecera</p>
                      <p>(max. 2 MB) (1500px*350px)</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <div class="tags">
            <span v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary">
                {{ file.name }}
                <button class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)">
                </button>
            </span>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="hero-foot ">
        <div class="buttons is-right p-6">
          <b-button type="is-dark" @click="goBack">Volver</b-button>
          <b-button type="is-success" :disabled="invalid" @click="saveOrUpdateCompany">{{ isEditing ? 'Editar' :
            'Guardar'
            }}</b-button>
        </div>
      </div>
    </validation-observer>
</template>

<script>
export default {
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    company: Object
  },
  data () {
    return {
      companyForm: {
        description: '',
        id: null,
        name: ''
      },
      dropFiles: []
    }
  },
  name: 'AddCompany',
  methods: {
    deleteDropFile (index) {
      this.dropFiles.splice(index, 1)
    },
    goBack () {
      this.$emit('finishedAddingCompany', false)
    },
    saveCompany () {
      this.axios.post(process.env.VUE_APP_API_CREATE_COMPANY, this.companyForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addCompany.created.title'),
          message: this.$i18n.t('addCompany.created.message')
        })
        this.$emit('finishedAddingCompany', true)
      }).catch(err => {
        console.log('Error en la creación de la empresa', err)
      })
    },
    saveOrUpdateCompany () {
      if (this.isEditing) {
        this.updateCompany()
      } else {
        this.saveCompany()
      }
    },
    updateCompany () {
      this.axios.put(process.env.VUE_APP_API_UPDATE_COMPANY, this.companyForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addCompany.updated.title'),
          message: this.$i18n.t('addCompany.updated.message')
        })
        this.$emit('finishedAddingCompany', true)
      }).catch(err => {
        console.log('Error en la modificación de la empresa', err)
      })
    }

  },
  created () {
    if (this.isEditing) {
      this.companyForm.id = this.company.id
      this.companyForm.name = this.company.name
      this.companyForm.description = this.company.description
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
