var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-fullheight-with-navbar is-bg-gray p-6"
  }, [!_vm.addCompany ? _c('div', {
    attrs: {
      "id": "companyTable"
    }
  }, [_c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "navbar-menu",
    attrs: {
      "id": "navbarMenuHeroB"
    }
  }, [_c('div', {
    staticClass: "navbar-end"
  }, [_c('span', {
    staticClass: "navbar-item"
  }, [_c('b-button', {
    staticClass: "button is-info is-primary",
    on: {
      "click": _vm.handleAddCompany
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "plus"
    }
  }), _c('span', [_vm._v("Añadir empresa")])], 1)], 1)])])])])]), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('section', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "block is-full"
  }, [_c('b-field', {
    attrs: {
      "grouped": "",
      "group-multiline": "",
      "position": "is-right"
    }
  }, [_c('b-field', [_c('b-input', {
    attrs: {
      "placeholder": "Buscar empresa...",
      "type": "search",
      "icon": "search"
    },
    model: {
      value: _vm.filters.companySearch,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "companySearch", $$v);
      },
      expression: "filters.companySearch"
    }
  })], 1), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-button', {
    staticClass: "button is-link",
    on: {
      "click": function click($event) {
        return _vm.fetchCompanies();
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "filter"
    }
  }), _vm._v(" Filtrar ")], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "block m-4"
  }, [_c('b-table', {
    attrs: {
      "data": _vm.data,
      "backend-filtering": "",
      "backend-sorting": "",
      "backend-pagination": "",
      "draggable": "",
      "hoverable": _vm.isHoverable,
      "loading": _vm.loadingCompany,
      "current-page": _vm.currentPage
    },
    on: {
      "sort": _vm.handleTableSort,
      "dragstart": _vm.dragstart,
      "drop": _vm.drop,
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "id",
      "label": "REF",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.id) + " ")];
      }
    }], null, false, 2188296272)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "name",
      "label": "Empresa",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.name) + " ")];
      }
    }], null, false, 2982927002)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Acciones"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm.isNotAdminCompany(props.row) ? _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Editar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "primary",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleEditCompany(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "pen"
          }
        })], 1)], 1) : _vm._e(), _vm.isNotAdminCompany(props.row) ? _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Eliminar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "danger",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteCompany(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)], 1) : _vm._e()];
      }
    }], null, false, 1082530003)
  })], 1)], 1), _c('div', {
    staticClass: "block"
  }, [_c('el-pagination', {
    staticClass: "mt-5",
    attrs: {
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-sizes": [20, 50, 100, 200],
      "page-size": _vm.pageSize,
      "layout": "total, sizes, prev, pager, next",
      "total": _vm.totalElements
    },
    on: {
      "size-change": _vm.handlePageSizeChange,
      "current-change": _vm.handlePageChange,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  })], 1)])])])])]) : _vm._e(), _vm.addCompany ? _c('div', {
    attrs: {
      "id": "addCompany"
    }
  }, [_c('add-company', {
    attrs: {
      "company": _vm.selectedCompany,
      "is-editing": _vm.isEditing
    },
    on: {
      "finishedAddingCompany": _vm.handleCompanyAdded
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h1', [_vm._v("Empresas")])]);
}]

export { render, staticRenderFns }