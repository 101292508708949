var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "hero-head"
        }, [_c('div', {
          staticClass: "navbar"
        }, [_c('div', {
          staticClass: "container"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar Empresa' : 'Nueva Empresa'))])])])])]), _c('div', {
          staticClass: "hero-body"
        }, [_c('div', {
          staticClass: "container has-text-left"
        }, [_c('section', {
          staticClass: "box"
        }, [_c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "company"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors,
                  valid = _ref2.valid;
              return _c('b-field', {
                attrs: {
                  "horizontal": "",
                  "label": "Empresa",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "placeholder": "Nombre de la empresa"
                },
                model: {
                  value: _vm.companyForm.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.companyForm, "name", $$v);
                  },
                  expression: "companyForm.name"
                }
              })], 1);
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "description"
          }
        }, [_c('b-field', {
          attrs: {
            "horizontal": "",
            "label": "Descripción"
          }
        }, [_c('b-input', {
          attrs: {
            "maxlength": "100",
            "type": "textarea",
            "placeholder": "Información de interés"
          },
          model: {
            value: _vm.companyForm.description,
            callback: function callback($$v) {
              _vm.$set(_vm.companyForm, "description", $$v);
            },
            expression: "companyForm.description"
          }
        })], 1)], 1), false ? _c('b-field', {
          attrs: {
            "horizontal": "",
            "label": "Imagen de cabecera"
          }
        }, [_c('b-upload', {
          attrs: {
            "multiple:": "",
            "false": "",
            "drag-drop": ""
          },
          model: {
            value: _vm.dropFiles,
            callback: function callback($$v) {
              _vm.dropFiles = $$v;
            },
            expression: "dropFiles"
          }
        }, [_c('section', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "content has-text-centered"
        }, [_c('p', [_c('b-icon', {
          attrs: {
            "icon": "upload",
            "size": "is-large"
          }
        })], 1), _c('p', [_vm._v("Arrastra aquí la imagen de cabecera")]), _c('p', [_vm._v("(max. 2 MB) (1500px*350px)")])])])])], 1) : _vm._e(), _c('div', {
          staticClass: "tags"
        }, _vm._l(_vm.dropFiles, function (file, index) {
          return _c('span', {
            key: index,
            staticClass: "tag is-primary"
          }, [_vm._v(" " + _vm._s(file.name) + " "), _c('button', {
            staticClass: "delete is-small",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteDropFile(index);
              }
            }
          })]);
        }), 0)], 1)])])]), _c('div', {
          staticClass: "hero-foot "
        }, [_c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark"
          },
          on: {
            "click": _vm.goBack
          }
        }, [_vm._v("Volver")]), _c('b-button', {
          attrs: {
            "type": "is-success",
            "disabled": invalid
          },
          on: {
            "click": _vm.saveOrUpdateCompany
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? 'Editar' : 'Guardar'))])], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }