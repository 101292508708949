<template>
  <section class="hero is-fullheight-with-navbar is-bg-gray p-6">
    <div id="companyTable" v-if="!addCompany">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1>Empresas</h1>
            </div>
            <div id="navbarMenuHeroB" class="navbar-menu">
              <div class="navbar-end">
              <span class="navbar-item">
                  <b-button class="button is-info is-primary" @click="handleAddCompany">
                    <v-icon icon="plus" class="g-icon"></v-icon>
                    <span>Añadir empresa</span>
                  </b-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container">
          <div class="block">
            <section class="box">
              <div class="block is-full">
                <b-field grouped group-multiline position="is-right">
                  <b-field>
                    <b-input placeholder="Buscar empresa..." type="search" icon="search"
                             v-model="filters.companySearch"></b-input>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-button class="button is-link" @click="fetchCompanies()">
                        <v-icon icon="filter" class="g-icon"></v-icon>
                        Filtrar
                      </b-button>
                    </p>
                  </b-field>
                </b-field>
              </div>
              <div class="block m-4">
                <b-table
                  :data="data"
                  backend-filtering
                  backend-sorting
                  backend-pagination
                  @sort="handleTableSort"
                  draggable
                  @dragstart="dragstart"
                  @drop="drop"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  :hoverable="isHoverable"
                  :loading="loadingCompany"
                  :current-page.sync="currentPage">
                  <b-table-column centered field="id" label="REF" sortable v-slot="props">
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column centered field="name" label="Empresa" sortable v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>
                  <b-table-column centered label="Acciones" v-slot="props">
                    <el-tooltip class="item" effect="dark" content="Editar" placement="top" v-if="isNotAdminCompany(props.row)">
                      <el-button size="mini" type="primary" circle @click="handleEditCompany(props.row)">
                        <v-icon icon="pen"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Eliminar" placement="top" v-if="isNotAdminCompany(props.row)">
                      <el-button size="mini" type="danger" circle @click="handleDeleteCompany(props.row)">
                        <v-icon icon="trash"></v-icon>
                      </el-button>
                    </el-tooltip>
                  </b-table-column>
                </b-table>
              </div>
              <div class="block">
                <el-pagination class="mt-5"
                               :hide-on-single-page="true"
                               @size-change="handlePageSizeChange"
                               @current-change="handlePageChange"
                               :current-page.sync="currentPage"
                               :page-sizes="[20, 50, 100, 200]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next"
                               :total="totalElements">
                </el-pagination>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div id="addCompany" v-if="addCompany">
      <add-company @finishedAddingCompany="handleCompanyAdded" :company="selectedCompany"
                   :is-editing="isEditing"></add-company>
    </div>
  </section>
</template>

<script>
import AddCompany from '../components/company/AddCompany'

export default {
  components: { AddCompany },
  created () {
    this.fetchCompanies()
  },
  data () {
    return {
      addCompany: false,
      currentPage: 1,
      data: [],
      draggingRow: null,
      draggingRowIndex: null,
      filters: {
        companySearch: '',
        active: 'Todos'
      },
      isEditing: false,
      isHoverable: true,
      loadingCompany: false,
      pageSize: 20,
      rangeBefore: 3,
      rangeAfter: 1,
      selectedCompany: null,
      sortOption: 'id,desc',
      totalElements: 200
    }
  },
  methods: {
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    drop (payload) {
      console.log(payload)
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index
      this.$buefy.toast.open(`Moved ${this.draggingRow.company} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    },
    fetchCompanies () {
      this.loadingCompany = true
      this.axios.get(process.env.VUE_APP_API_GET_COMPANY, {
        params: {
          searchQuery: this.getSearchQuery(),
          sort: this.sortOption,
          page: this.currentPage - 1,
          size: this.pageSize
        }
      })
        .then(response => {
          const companyList = response.data.content
          this.totalElements = response.data.totalElements
          this.data = companyList.map(company => {
            return {
              id: company.id,
              description: company.description,
              name: company.name,
              questionnaires: 2,
              employees: company.users,
              active: true

            }
          })
          this.totalElements = response.data.totalElements
        })
        .catch(err => {
          console.err('Error obteniendo las empresas', err)
        })
        .finally(() => {
          this.loadingCompany = false
        })
    },
    getSearchQuery () {
      let searchQuery = ''
      console.log('filt', this.filters)
      console.log('test', this.filters.companySearch)
      if (this.filters.companySearch) {
        searchQuery += 'name$' + this.filters.companySearch
      }
      console.log('search', searchQuery)
      return searchQuery
    },
    handleAddCompany () {
      this.addCompany = true
    },
    handleCompanyAdded (isCreated) {
      if (isCreated) {
        this.fetchCompanies()
      }
      this.resetStatus()
    },
    handlePageChange (page) {
      this.currentPage = page
      this.fetchCompanies()
    },
    handlePageSizeChange (pageSize) {
      this.pageSize = pageSize
      this.currentPage = 0
      this.fetchCompanies()
    },
    handleEditCompany (company) {
      this.selectedCompany = company
      this.isEditing = true
      this.addCompany = true
    },
    handleDeleteCompany (company) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Empresa',
        message: '¿Está seguro de que quiere <b>eliminar</b> la empresa? Esto eliminará también sus usuarios, sus fichas de análisis y toda la información relacionada',
        confirmText: 'Eliminar empresa',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.axios.delete(process.env.VUE_APP_API_DELETE_COMPANY, {
            params: {
              companyId: company.id
            }
          }).then(() => {
            this.$notify.success({
              title: 'Empresa eliminada',
              message: 'Empresa eliminada correctamente'
            })
            this.fetchCompanies()
          }).catch(err => {
            console.log('No se puede eliminar la empresa', err)
          })
        }
      })
    },
    handleTableSort (field, order) {
      this.sortOption = field + ',' + order
      this.fetchCompanies()
    },
    isNotAdminCompany (company) {
      return company.id !== 0
    },
    resetStatus () {
      this.addCompany = false
      this.selectedCompany = null
      this.isEditing = false
      this.sortOption = 'id,desc'
    }
  }
}
</script>

<style lang="scss">

</style>
